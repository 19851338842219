<template>
  <div class="lab-results box-page-new">
    <div class="tab__menus">
      <tabs-menu>
        <template v-slot:header>
          <router-link to="/patient/lab-result" class="tabs-link">
            <li>{{ $t("summary") }}</li>
          </router-link>
          <div class="tabs-link active">
            <li>{{ $t("history") }}</li>
          </div>
        </template>
      </tabs-menu>
    </div>
    <div
      class="tab__contents"
      :class="{ 'tab__contents--active': activeTab == 2 }"
    >
      <div class="tab__selects">
        <v-select
          @change="currentDataItems"
          v-model="activeTab"
          :items="listTab"
          item-text="title"
          item-value="id"
          dense
        />
      </div>
    </div>
    <div class="content-table">
      <v-row
        dense
        v-for="(history, index) of history"
        :key="index"
      >
        <v-col cols="12" v-if="history.approved == true">
          <div class="card--order">
            <v-row no-gutters>
              <v-col cols="2" md="1" class="d-none d-md-flex align-start justify-start">
                <img src="@/assets/Vector.png" height="55" />
              </v-col>
              <v-col cols="12" md="8" class="order-details">
                <template v-if="history.booking_code != null">
                  <div style="color: #282828; font-size: 15px">
                    {{ $t("booking_code") }}
                  </div>
                  <div>
                    <h4 class="order-code">
                      {{ history.booking_code }}
                    </h4>
                  </div>
                </template>
                <div style="color: #282828; font-size: 15px">
                  {{ $t("order_number") }}
                </div>
                <div>
                  <span>
                    {{ history.order_number }}
                  </span>
                </div>
                <div style="color: #282828; font-size: 15px">
                  {{ $t("menu.test") }}
                </div>
                <span
                  class="order-product"
                  v-for="(product, productIndex) of history.product_order"
                  :key="productIndex"
                  >{{ product
                  }}{{
                    history.product_order.length - 1 == productIndex
                      ? "."
                      : ", "
                  }}
                </span>
              </v-col>
              <v-col cols="12" md="3" class="branch-time" align="right">
                <div class="button-show-md" align="right">{{ formatDate(history.created_at) }}</div>
                <div class="button-show-md" align="right">{{ history.plant }}</div>
                
                <div class="button-show-sm" align="left"><div style="color: #282828; font-size: 15px" >
                  {{ $t("purchase_date") }}
                </div>{{ formatDate(history.created_at) }}</div>
                <div class="button-show-sm" align="left"><div style="color: #282828; font-size: 15px" >
                  {{ $t("menu.branch_location") }}
                </div>{{ history.plant }}</div>
                <v-row dense class="button-show-md" >
                  <v-col cols="12">
                    <v-btn
                      @click="previewPdf(history.order_number)"
                      class="btn-view"
                    >
                      {{ $t("view_report") }}
                    </v-btn>
                  </v-col>
                  <v-col cols="12">
                    <v-btn
                      @click="downloadPdf(history.order_number)"
                      class="btn-download"
                    >
                      {{ $t("download_report") }}
                    </v-btn>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
            <v-row no-gutters class="button-show-sm">
              <v-col cols="12">
                <v-row dense>
                  <v-col cols="6" class="d-flex align-center justify-start">
                    <v-btn
                      @click="previewPdf(history.order_number)"
                      class="btn-view"
                    >
                      {{ $t("view_report") }}
                    </v-btn>
                  </v-col>
                  <v-col cols="6" class="d-flex align-center justify-start">
                    <v-btn
                      @click="downloadPdf(history.order_number)"
                      class="btn-download"
                    >
                      {{ $t("download_report") }}
                    </v-btn>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </div>
          <hr />
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
// import TabContent from "@/components/v2/tabs/index.vue";
import API from "@/service/api_service.js";
import Auth from "@/service/auth_service";
import TabsMenu from "@/components/general/TabsMenu.vue";

export default {
  components: { TabsMenu },
  data: () => ({
    activeTab: 2,
    listTab: [
      {
        id: 1,
        title: "Summary",
      },
      {
        id: 2,
        title: "History",
      },
    ],
    history: {},
    download: {},
    preview: {},
  }),
  async mounted() {
    this.$store.commit('setLoading', true)
    await API.get(`${process.env.VUE_APP_API_TRUST}diagnosis/get-history`, {
      Authorization: `Bearer ${Auth.getToken()}`,
    }).then((res) => {
      if (res.statusCode === 200) {
        this.history = res.results;
      }
    });
    this.$store.commit('setLoading', false)
  },
  methods: {
    currentDataItems() {
      if (this.activeTab == 1) {
        this.$router.push("/patient/lab-result");
      } else if (this.activeTab == 2) {
        this.$router.push("/patient/lab-result/history");
      }
    },
    formatDate(date) {
      
      if (!date) return null;
      const MMM = [
        "Jan",
         "Feb",
         "Mar",
         "Apr",
         "May",
        "June",
         "July",
        "Aug",
         "Sept",
        "Oct",
        "Nov",
         "Dec",
      ];
      // const [year, month, day] = date.split("-");
      // return `${day} ${MMM[month]} ${year}`;
      const getDate = new Date(date);
      const formatDate = `${("0" + getDate.getDate()).slice(-2)} ${
        MMM[getDate.getMonth()]
      } ${getDate.getFullYear()} ${(getDate.getHours()<10?'0':'') + getDate.getHours()}:${(getDate.getMinutes()<10?'0':'') + getDate.getMinutes()}:${(getDate.getSeconds()<10?'0':'') + getDate.getSeconds()}`;
      return formatDate;
   },
    downloadPdf(param) {
      API.get(
        `${process.env.VUE_APP_API_TRUST}diagnosis/get-labreport?order_number=${param}`,
        {
          Authorization: `Bearer ${Auth.getToken()}`,
        }
      ).then((res) => {
        this.download = res;
        window.open(this.download.linkDownload, "_blank");
      });
    },
    previewPdf(param) {
      API.get(
        `${process.env.VUE_APP_API_TRUST}diagnosis/get-labreport?order_number=${param}`,
        {
          Authorization: `Bearer ${Auth.getToken()}`,
        }
      ).then((res) => {
        this.preview = res;
        window.open(this.preview.linkPreview, "_blank");
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/css/custom.scss";
.box-page-new {
  margin: 15px 24px;
}
.tabs {
  background: #e5e5e5 !important;
}
.content-table {
  padding: 35px 18px 18px 18px;
  @media screen and (min-width: 960px) {
    padding: 35px 18px 18px 36px;
  }
  @media screen and (max-width: 958px) {
    padding: 5px 18px 18px 18px;
  }
}
@media screen and (min-width: 750px) {
  .button-show-md{
    display: block;
  }
  .button-show-sm{
    display: none;
  }
}
@media screen and (max-width: 749px) {
  .button-show-sm{
    display: block;
  }
  .button-show-md{
    display: none;
  }
}
.button-show-sm{
  margin-top: 10px;
}
.btn-view {
  color: #fff !important;
  background: radial-gradient(
    118.75% 157.07% at 34.74% -18.75%,
    #5b86e4 0%,
    rgb(54, 216, 238) 100%
  );
  // box-shadow: -4.74667px 8px 30px rgba(35, 63, 120, 0.25);
  border-radius: 12px;
  height: 26px !important;
  margin-top: 10px;
  text-transform: capitalize;
}
.btn-download {
  color: #fff !important;
  background: radial-gradient(
    118.75% 157.07% at 34.74% -18.75%,
    #5278c7 0%,
    $blue_1 100%
  );
  // box-shadow: -4.74667px 8px 30px rgba(35, 63, 120, 0.25);
  border-radius: 12px;
  height: 26px !important;
  margin-top: 10px;
  text-transform: capitalize;
}
.btn-waiting {
  color: #fff !important;
  background: radial-gradient(
    118.75% 157.07% at 34.74% -18.75%,
    #fdf913 0%,
    rgb(235, 136, 7) 100%
  );
  // box-shadow: -4.74667px 8px 30px rgba(35, 63, 120, 0.25);
  border-radius: 12px;
  height: 26px !important;
  margin-top: 10px;
  text-transform: capitalize;
}
.card--order {
  padding: 15px 15px;
  margin-top: 15px;
}
.order-details {
  text-align: left;
  color: #93969b;
  font-size: 14px;
}
.branch-time {
  // text-align: right;
  color: #93969b;
  font-size: 14px;
}
.order-product {
  font-size: 14px;
  color: #93969b !important;
}
.order-code {
  font-size: 20px;
  color: black !important;
}
</style>
