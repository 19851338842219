<template>
  <div class="tabs">
    <v-container class="pa-0">
      <div class="d-flex flex-column-reverse flex-md-row justify-space-between">
        <ul>
          <slot name="header"></slot>
        </ul>
        <slot name="footer"></slot>
      </div>
    </v-container>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
@import "../../assets/css/custom.scss";
.tabs {
  background: $main_2;
  ul {
    padding-left: 0;
    list-style: none;
    display: inline-block;
    border-radius: 6px 6px 0 0;
    overflow: hidden;
    font-weight: 400;
    .tabs-link {
      display: inline-block;
      padding: 10px 30px;
      color: white;
      background: #00a4b3;
      text-decoration: none;
      a {
        // color: #7A96BD;
        color: #00a4b3;
        text-decoration: none;
      }
      &.active {
        font-weight: 500;
        color: #00a4b3;
        background: white;
      }
    }
  }
}
</style>
